import { NavigationEnd, Router } from '@angular/router';
import { AppService } from './app.service';
import { CommonService } from '@app/core/services/common.service';
import { UserService, LayoutService } from './core/services';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { environment } from '@env/environment';
import { EmitService } from './ts/services/emit.service';
import { VersionCheckService } from "@app/shared/versioncheck/version-check.service";
import { Component, Renderer2, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;
@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet> <div class="cookie-consent hide-consent show-consent" *ngIf="showCookieConsent"><span class="cookie-text">{{'common_login_cookie_content' |i18n}}<a [href]="accountsUrl+'privacy-policy'" target="_blank"> {{'common_all_privacy_policy' | i18n}} </a></span><button class="consent-btn mt-4" (click)="hideCookieConsent()"> {{'footer_cookie_gotIt' | i18n}}</button></div>`,
})
export class AppComponent {
  currentUrl;
  idleState = "NOT_STARTED";
  countdown ? : number = null;
  lastPing ? : Date = null;
  action = '';
  showCookieConsent: boolean = true;
  accountsUrl = "";
  companyCookie: any;
  faviSrc;

  constructor(private renderer: Renderer2, 
      private el: ElementRef, 
      private appService: AppService, 
      private router: Router, 
      private commonService: CommonService, 
      private idle: Idle, 
      private layoutService: LayoutService, 
      private emitService: EmitService, 
      private versionCheckService: VersionCheckService,
      private _sanitizer: DomSanitizer
  ) {
    this.appService.setLocalStorageFunctions();
    /* const fullUrl = window.location.href;
    let domain;
    
    if (fullUrl.startsWith(baseUrl)) {
      // Remove the base URL part
      const domainPart = fullUrl.slice(baseUrl.length);
      // Extract the domain part up to the first '/'
      domain = domainPart.split('/')[0];
    } */

    // const baseUrl = 'http://testaccounts.';
    const host = window.location.host
    const parts = host.split(".");
    parts.shift();
    const domain = parts.join(".");
    console.log(domain);

    // let localurl = baseUrl + domain + '/#/';
    // this.accountsUrl = localurl;
    localStorage.setEncryptedItem('domain', domain);
    // let API_END_POINT_ACCOUNTS = 'http://testaccounts.api.' + domain + ':4000/api/v1/'; // For local
    let API_END_POINT_ACCOUNTS = 'https://fjy29.api.' + domain + '/api/v1/';
    localStorage.setEncryptedItem('API_END_POINT_ACCOUNTS', API_END_POINT_ACCOUNTS);  
    this.getWhiteLabelConfig(domain);

    let code = window.location.search;
    let urldetail = code.substring(code.indexOf("?") + 1, code.indexOf("="))
    if (urldetail != "" && urldetail == "code") {
      let cde = code.substring(code.indexOf("=") + 1, code.indexOf("&"));
      let state = code.split('=').pop();
      let continuePath;
      this.commonService.setLinkdInCode(cde);
      if (localStorage.getEncryptedItem("loginFrom"))
        continuePath = localStorage.getEncryptedItem("loginFrom")
      continuePath ? this.router.navigate(["/mask", continuePath]) : this.router.navigate(["/mask"])
    }
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = e.url;
      }
    });
    setInterval(() => {
      let formData = new FormData();
      formData.append("domain", "." + localStorage.getEncryptedItem('domain'));
      if (this.currentUrl != "/" && !this.currentUrl.startsWith('/auth'))

        this.appService.createAccessToken(formData).subscribe(data => {});
    }, 600000);
    // set idle parameters
    this.idle.setIdle(1800); // how long can they be inactive before considered idle, in seconds
    this.idle.setTimeout(10); // how long can they be idle before considered timed out, in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
    });
    // do something when the user is no longer idle
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      this.countdown = null;
      // cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    this.idle.onTimeout.subscribe(() => {
      this.idleState = "TIMED_OUT";
      let currentURL = window.location.href
      let lastActive;
      lastActive = Date.parse(this.commonService.decrypt(this.commonService.getCookie(this.commonService.encrypt(environment.USER_ACTIVE_CHECK))))
      let timeDiff;
      timeDiff = Date.now() - lastActive;
      let minuteDiff = timeDiff / (1000 * 60)
      if (minuteDiff > 30) {
        let domain = localStorage.getEncryptedItem('domain');
        let accounts_url = localStorage.getEncryptedItem('ACCOUNTS_URL');
        localStorage.clear();
        window.location.href = accounts_url+'auth/logout/' + encodeURIComponent(this.commonService.encrypt(currentURL));
      } else {
        this.reset();
      }
    });
    // do something as the timeout countdown does its thing
    this.idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);

    let cookieExist = this.commonService.decrypt(decodeURIComponent(this.commonService.getCookie(environment.checkCookie)));
    if (cookieExist) {
      cookieExist = JSON.parse(cookieExist)
      localStorage.setEncryptedItem('userMail', cookieExist['userMail']);
      localStorage.setEncryptedItem('partyID', cookieExist['partyID']);
      localStorage.setEncryptedItem('userID', cookieExist['userID']);
      localStorage.setEncryptedItem('accId', cookieExist['accId']);
      localStorage.setEncryptedItem('isBexioCompany', cookieExist.hasOwnProperty('isBexioCompany') ? cookieExist['isBexioCompany'] : 'false');
    }


  }
  getWhiteLabelConfig(domain: any) {
    let formData = new FormData();
    formData.append('domain', domain);
    let object = this.commonService.createObject(formData);
    let token = this.commonService.createToken(object);
    let encryptedToken = this.commonService.encrypt(token);
    let encrData = new FormData();
    encrData.append('encrToken', encryptedToken);
    this.appService.getWhiteLabelConfig(encrData).subscribe(data => {
      if (data.status) {
        let config = data['data']['configdata'];
        localStorage.setEncryptedItem('configData',JSON.stringify(config));
        localStorage.setEncryptedItem('API_END_POINT_ACCOUNTS',config['accounts_api_url']);
        localStorage.setEncryptedItem('API_END_POINT_ADMIN',config['admin_api_url']);
        localStorage.setEncryptedItem('API_END_POINT_GATE2B',config['gate2b_api_url']);
        localStorage.setEncryptedItem('domain', config['domain']);
        localStorage.setEncryptedItem('ACCOUNTS_URL',config['accounts_url']);
        localStorage.setEncryptedItem('ADMIN_URL',config['admin_url']);
        localStorage.setEncryptedItem('GATE2B_URL',config['gate2b_url']);
        this.emitService.setWhiteLabelConfig(config);
        this.commonService.setWhiteLabelImageData(data['data']['imageData']);
        const currentTheme = $("body").hasClass("smart-style-1") ? 'dark_theme' : 'light_theme';
        setTimeout(() => {
          // result = FAVI[domain][0].title;
          document.getElementById('outputFaviTitle').setAttribute("innerHTML",config['favi_title']);
          document.getElementById('outputFaviTitle').innerHTML = config['favi_title'];
          // let imageData = this.commonService.getWhiteLabelImageData("favicon");
          console.log("data['data']['imageData']",data['data']['images']);
          
          this.faviSrc = this._sanitizer.bypassSecurityTrustResourceUrl(data['data']['configdata']['images']['favicon']['filePath']);
          document.getElementById('faviIcon').setAttribute("href",this.faviSrc);
          // var a = document.getElementById('faviIcon'); //or grab it by tagname etc
          // a.setAttribute("href","somelink url")
        });

        let style = config['color'];
        for (const [cssVar, themeColors] of Object.entries(style)) {
          // Set the CSS variable for the current theme
          if (themeColors[currentTheme]) {
              document.documentElement.style.setProperty(cssVar, themeColors[currentTheme]);
          }
        }

        const styleElement = document.createElement('style');
        styleElement.textContent = style.extraStyle;
        document.head.appendChild(styleElement);
      }
    })
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  ngOnInit() {
    $("body").addClass(localStorage.getEncryptedItem('domain').split(".")[0]);
    // DONT'T DELETE ,CODE FOR DYNAMIC STYLE FOR WHITE LABEL

    // console.log('window.location.href',window.location.href);
    // if(window.location.href.indexOf('whiteLabel') == -1){
    //   let formData = new FormData();
    //   formData.append("domain","."+localStorage.getEncryptedItem('domain'));
    //   this.appService.getConfig(formData).subscribe(res=>{
    //     if(res['data'].length != 0){
    //       console.log('res',res['data']);
    //       localStorage.setEncryptedItem('config',JSON.stringify(res['data']))
    //       Object.keys(res['data'].color).forEach(element => {
    //         document.documentElement.style.setProperty(element, res['data'].color[element]);
    //       });
    //     }

    //   })
    // }else{
    //   localStorage.removeEncryptedItem('config')
    // }
    // END

    // setTimeout(() => {
    //   console.log('564');
    //   this.renderer.setStyle(this.el.nativeElement.ownerDocument.documentElement, '--primary-color', 'red');
    // }, 10000);
    // if(localStorage.getEncryptedItem('domain') == 'rahul.li')
    // {
    //   document.documentElement.style.setProperty('--theme-color-1', 'red');
    // }else{
    //   document.documentElement.style.setProperty('--theme-color-1', 'blue');
    // }
    console.log(environment.STYLES[localStorage.getEncryptedItem('domain')]);
    environment.STYLES[localStorage.getEncryptedItem('domain')].forEach(element => {
      document.documentElement.style.setProperty(Object.keys(element)[0], element[Object.keys(element)[0]]);
      // console.log('element',element);

    });
    this.reset();
    this.cookieConsent();

    this.versionCheckService.initVersionCheck('version.json');
  }
  cookieConsent(test ? ) {
    if (this.commonService.getCookie(environment.cookieConsent) == 'false') {
      this.showCookieConsent = false;
    } else {
      this.showCookieConsent = true;
      this.commonService.setCookie(environment.cookieConsent, "true", 10000, environment.cookieDomain, '/');
    }
  }
  hideCookieConsent(delay = 0) {
    if (this.showCookieConsent) {
      setTimeout(() => {
        $(".cookie-consent").fadeOut(1500);
        this.showCookieConsent = false;
        this.commonService.setCookie(environment.cookieConsent, "false", 10000, environment.cookieDomain, '/');
      }, delay)
    }
  }

}